import React from 'react';
import * as S from './styles';
import { IconChevronUp } from '@tabler/icons-react';
import useText from 'app/presentation/hooks/useText';
import { Statistic } from 'utils/statistic';
import { formatSecondsInterval } from 'utils/time';
import { Tooltip } from '../Tooltip';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    children: React.ReactNode[] | React.ReactNode;
}

export const InsightsCard: React.FC<Props> = ({
    children, ...rest
}) => {
    return (
        <S.InsightsCard {...rest}>
            {children}
        </S.InsightsCard>
    );
};

interface CardTitleProps extends Props {
    tooltip?: string;
}

export const InsightsCardTitle: React.FC<CardTitleProps> = ({
    children, tooltip, ...rest
}) => {
    return (
        <S.InsightsCardTitle {...rest}>
            <span>{children}</span>
            {tooltip && (
                <Tooltip
                    text={tooltip}
                />
            )}
        </S.InsightsCardTitle>
    );
};

interface StatisticProps extends React.HTMLAttributes<HTMLDivElement> {
    description?: string;
    statistic: Statistic;
    timeStatistic?: boolean;
    suffix?: string;
    valueFractionDigits?: number;
    increaseFractionDigits?: number;
    lastIntervalDays?: number;
    statisticFontSize?: number;
}

export const InsightsCardStatistic: React.FC<StatisticProps> = ({
    description,
    statistic,
    suffix = '',
    timeStatistic = false,
    valueFractionDigits = 1,
    increaseFractionDigits = 1,
    lastIntervalDays,
    statisticFontSize = 32,
    ...rest
}) => {
    const { numberFormatter } = useText()
    const f = numberFormatter(increaseFractionDigits, increaseFractionDigits)
    const { textGetter } = useText()
    const t = textGetter('agent.components.insights-card')

    return (
        <S.InsightsCardStatistic statisticFontSize={statisticFontSize} {...rest}>
            {description && (
                <span className='desc'>{description}</span>
            )}
            <div className='statistic'>
                <span className='statistic-value'>
                    {
                        (
                            timeStatistic
                                ? formatSecondsInterval(statistic.getValue())
                                : f(statistic.getValue(), valueFractionDigits)
                        ) + suffix
                    }
                </span>
                {statistic.hasPreviousValue() && (
                    <div className={
                        'percentage-increase' +
                        (statistic.hasValueDecreased() ? ' percentage-decrease' : '')
                    }>
                        {/*<IconChevronUp />*/}
                        {/*<span className='percentage-value'>{f(statistic.increasePercentageModule())}%</span>*/}
                        {/*lastIntervalDays && (
                            <span className='last-interval'>
                                {
                                    lastIntervalDays > 1 
                                        ? t('last-days',{
                                            days: lastIntervalDays
                                        })
                                        : t('last-day')
                                }
                            </span>
                        )*/}
                    </div>
                )}
            </div>
        </S.InsightsCardStatistic>
    );
};
